import { Section } from "@/components/common/section/Section";
import styles from "./LearnMoreBlock.module.scss";

type Paragraph = {
  title: string;
  text: string;
};

export type LearnMoreBlockProps = {
  title: string;
  paragraphs: Paragraph[];
};

export const LearnMoreBlock = ({ title, paragraphs }: LearnMoreBlockProps) => (
  <Section>
    <div className={styles.learnMoreBlock}>
      <h2 className={styles.sectionTitle}>{title}</h2>
      <div className={styles.paragraphGrid}>
        {paragraphs.map((paragraph) => (
          <div key={paragraph.title}>
            <h3 className={styles.paragraphTitle}>{paragraph.title}</h3>
            <p className={styles.paragraphText}>{paragraph.text}</p>
          </div>
        ))}
      </div>
    </div>
  </Section>
);

export default LearnMoreBlock;
