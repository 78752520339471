import Image from "next/image";
import Link from "next/link";
import { useIntl } from "react-intl";

import {
  addBasePathToStrapiMedia,
  Cta,
  formatStrapiEntity,
  StrapiEntity,
  StrapiImage,
} from "@/utils/strapi";

import { defaultImageQuality } from "@/const/imageQuality";
import styles from "./ServiceCard.module.scss";

enum Category {
  PRO = "servicePro",
  PARTICULIER = "serviceParticulier",
  UNRIENCESTTOUT = "unRienCestTout",
  PARTENARIAT = "partenariat",
}

export type ServiceCardProps = {
  title: string;
  description: string;
  category: Category;
  cta: Cta;
  image: StrapiEntity<StrapiImage>;
};

export const ServiceCard = ({
  title,
  description,
  category,
  image,
  cta,
}: ServiceCardProps) => {
  const intl = useIntl();
  const strapiImage = formatStrapiEntity(image);

  const navigateToCtaExternalURL = () => (window.location.href = cta.url);

  return (
    <div className={styles.cardContainer} onClick={navigateToCtaExternalURL}>
      <div className={styles.imageWrapper}>
        <Image
          src={addBasePathToStrapiMedia(strapiImage)}
          alt={strapiImage.alternativeText}
          layout="fill"
          objectFit="cover"
          sizes="(max-width: 959px) 100vw, (max-width: 1279px) 33vw, 25vw"
          quality={defaultImageQuality}
        />
      </div>
      <div className={styles.separator}></div>
      <div className={styles.cardContent}>
        <p
          className={
            `${styles.category} ` +
            (category === Category.PRO ? styles.pro : styles.particulier)
          }
        >
          {intl.formatMessage({ id: category })}
        </p>
        <Link href={cta.url}>
          <a>
            <h3 className={styles.title}>{title}</h3>
          </a>
        </Link>
        <p className={styles.description}>{description}</p>
        <div className={styles.cta}>{cta.label}</div>
      </div>
    </div>
  );
};
