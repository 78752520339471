import Image from "next/image";

import Link from "next/link";
import { ServicesIcon } from "@/public/icons/ServicesIcon";
import {
  addBasePathToStrapiMedia,
  formatStrapiEntity,
  StrapiEntity,
  StrapiImage,
} from "@/utils/strapi";
import { defaultImageQuality } from "@/const/imageQuality";
import { injectPurifiedHTML } from "@/utils/injectPurifiedHTML";
import { Section } from "@/components/common/section/Section";
import { removeEmptyRel } from "@/utils/removeEmptyRel";
import styles from "./AgenciesNetwork.module.scss";

type AgencyLink = {
  label: string;
  url: string;
};

type FindYourAgencyCta = {
  label: string;
  url: string;
};

export type AgenciesNetworkProps = {
  title: string;
  description: string;
  agencyLinks: AgencyLink[];
  image: StrapiEntity<StrapiImage>;
  findYourAgencyCta: FindYourAgencyCta;
};

export const AgenciesNetwork = ({
  image,
  title,
  description,
  agencyLinks,
  findYourAgencyCta,
}: AgenciesNetworkProps) => {
  const strapiImage = formatStrapiEntity(image);

  return (
    <Section containerStyle={styles.agenciesSectionColor}>
      <div className={styles.agenciesBlock}>
        <div className={styles.agenciesContainer}>
          <ServicesIcon />
          <h2>{title}</h2>
          <div
            className={styles.agenciesDescription}
            dangerouslySetInnerHTML={injectPurifiedHTML(
              removeEmptyRel(description)
            )}
          />
          <div className={styles.agenciesLinks}>
            {agencyLinks.map((agencyLink) => (
              <Link key={agencyLink.label} href={agencyLink.url}>
                <a className={styles.agencyLink}>{agencyLink.label}</a>
              </Link>
            ))}
          </div>
        </div>
        <div className={styles.imageBlock}>
          <div className={styles.imageWrapper}>
            <Image
              src={addBasePathToStrapiMedia(strapiImage)}
              alt={strapiImage.alternativeText}
              layout="fill"
              objectFit="contain"
              sizes="640px"
              quality={defaultImageQuality}
            />
          </div>
          <Link href={findYourAgencyCta.url}>
            <a className={styles.findYourAgency}>{findYourAgencyCta.label}</a>
          </Link>
        </div>
      </div>
    </Section>
  );
};

export default AgenciesNetwork;
