import { Section } from "@/components/common/section/Section";
import { Vehicle, VehicleCard } from "./vehicleCard/VehicleCard";
import styles from "./Vehicles.module.scss";

export type VehiclesBlock = {
  title: string;
  description: string;
  vehicles: Vehicle[];
};

export const Vehicles = ({ title, description, vehicles }: VehiclesBlock) => (
  <Section>
    <div className={styles.vehiclesBlock}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <div className={styles.cardsGrid}>
        {vehicles.map((vehicle) => (
          <VehicleCard key={vehicle.title} {...vehicle} />
        ))}
      </div>
    </div>
  </Section>
);

export default Vehicles;
