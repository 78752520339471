import { ReviewsConst } from "@/components/common/reviewsBlock/ReviewsBlock";
import { Layout } from "@/components/layout/Layout";
import { HomePageContent } from "@/components/pages/home/Home";
import { queryHome } from "@/strapiQuery/home";
import { queryReviewsConst } from "@/strapiQuery/queryReviewsConst";
import {
  LoadI18nMessagesProps,
  loadIntlMessages,
} from "@/utils/loadIntlMessages";
import { getStrapiEntity } from "@/utils/strapi";

export const getStaticProps = async (ctx: LoadI18nMessagesProps) => {
  const layout = await getStrapiEntity<Layout>("layout", "");

  const reviewsConst = await getStrapiEntity<ReviewsConst>(
    "reviews-const",
    queryReviewsConst
  );

  const homePageContent = await getStrapiEntity<HomePageContent>(
    "home",
    queryHome
  );

  const intlMessages = loadIntlMessages(ctx);

  return {
    props: { layout, reviewsConst, homePageContent, intlMessages },
    revalidate: 1,
  };
};

export { Home as default } from "@/components/pages/home/Home";
