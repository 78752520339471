import Image from "next/image";
import Link from "next/link";
import {
  addBasePathToStrapiMedia,
  Cta,
  formatStrapiEntity,
  StrapiEntity,
  StrapiImage,
} from "@/utils/strapi";
import { defaultImageQuality } from "@/const/imageQuality";
import styles from "./VehicleCard.module.scss";

export type Vehicle = {
  category: string;
  title: string;
  subtitle: string;
  description: string;
  cta: Cta;
  image: StrapiEntity<StrapiImage>;
};

export const VehicleCard = ({
  category,
  title,
  subtitle,
  description,
  cta,
  image,
}: Vehicle) => {
  const strapiImage = formatStrapiEntity(image);

  return (
    <div className={styles.container}>
      <h3 className={styles.category}>{category}</h3>
      <Link href={cta.url}>
        <a>
          <div className={styles.cardContainer}>
            <div className={styles.imageContainer}>
              <Image
                src={addBasePathToStrapiMedia(strapiImage)}
                alt={strapiImage.alternativeText}
                layout="fill"
                objectFit="contain"
                sizes="253px"
                quality={defaultImageQuality}
              />
            </div>
            <h4 className={styles.title}>{title}</h4>
            <div className={styles.separator} />
            <p className={styles.subtitle}>{subtitle}</p>
            <p className={styles.description}>{description}</p>
            <p className={styles.cta}>{cta.label}</p>
          </div>
        </a>
      </Link>
    </div>
  );
};
