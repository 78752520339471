export const ServicesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={200} height={22.572}>
    <path
      data-name="Trac\xE9 7902"
      d="M189.015 8.6a8.334 8.334 0 0 1 8.535 8.116c-.015 6.875-7.367 12.927-8.535 14.022-1.168-1.094-8.52-7.147-8.535-14.022a8.338 8.338 0 0 1 8.535-8.116"
      transform="translate(-55.246 -8.6)"
      style={{
        fill: "#e20613",
      }}
    />
    <path
      data-name="Trac\xE9 7903"
      d="M18.515 9.19a8.334 8.334 0 0 1 8.535 8.116c-.015 6.875-7.367 12.935-8.535 14.022-1.161-1.087-8.52-7.147-8.535-14.014a8.335 8.335 0 0 1 8.535-8.124"
      transform="translate(-9.98 -8.757)"
      style={{
        fill: "#e20613",
      }}
    />
    <path
      data-name="Trac\xE9 7904"
      d="M19.081 16.889a.743.743 0 1 1 1.484 0v3.063h3.063a.743.743 0 1 1 0 1.484h-3.055V24.5a.743.743 0 1 1-1.484 0v-3.065h-3.07a.743.743 0 1 1 0-1.484h3.063z"
      transform="translate(-11.376 -10.594)"
      style={{
        fill: "#fff",
      }}
    />
    <path
      data-name="Trac\xE9 7905"
      d="M19.081 16.889a.743.743 0 1 1 1.484 0v3.063h3.063a.743.743 0 1 1 0 1.484h-3.055V24.5a.743.743 0 1 1-1.484 0v-3.065h-3.07a.743.743 0 1 1 0-1.484h3.063z"
      transform="translate(-11.376 -10.594)"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeMiterlimit: 10,
        strokeWidth: ".724px",
      }}
    />
    <path
      data-name="Trac\xE9 7906"
      d="M188.463 23.062a.911.911 0 0 1 0-1.822h4.877a.911.911 0 0 1 0 1.822z"
      transform="translate(-57.126 -11.956)"
      style={{
        fill: "#fff",
      }}
    />
    <path
      data-name="Trac\xE9 7907"
      d="M188.463 23.062a.911.911 0 0 1 0-1.822h4.877a.911.911 0 0 1 0 1.822z"
      transform="translate(-57.126 -11.956)"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeMiterlimit: 10,
        strokeWidth: ".724px",
      }}
    />
    <path
      data-name="Trac\xE9 7908"
      d="M169.106 31.54a1.321 1.321 0 0 1-1.065.632 1.277 1.277 0 0 1-1.271-1.227 1.773 1.773 0 0 1 .22-.742l1.55-2.791a1.376 1.376 0 0 1 1.08-.632 1.291 1.291 0 0 1 1.285 1.227 1.8 1.8 0 0 1-.242.742z"
      transform="translate(-51.606 -13.427)"
      style={{
        fill: "#0069b4",
      }}
    />
    <path
      data-name="Trac\xE9 7909"
      d="M40.241 21.7h1.5a1.558 1.558 0 0 0 0-3.114h-1.5zm-2.211-3.9a1.058 1.058 0 0 1 1.146-1.146h2.6a3.5 3.5 0 0 1 3.761 3.511 3.454 3.454 0 0 1-3.673 3.482h-1.63v2.409a1.1 1.1 0 1 1-2.2 0z"
      transform="translate(-17.427 -10.737)"
      style={{
        fill: "#0069b4",
      }}
    />
    <path
      data-name="Trac\xE9 7910"
      d="M60.964 21.277h1.513a1.25 1.25 0 0 0 1.366-1.307 1.232 1.232 0 0 0-1.366-1.293h-1.513zM58.76 17.8a1.04 1.04 0 0 1 1.131-1.146h2.585c2.262 0 3.658 1.014 3.658 3.32a2.852 2.852 0 0 1-2.718 2.776l2.5 2.674a.952.952 0 0 1 .294.646 1.114 1.114 0 0 1-1.954.749l-3.261-3.952h-.029v3.188a1.1 1.1 0 1 1-2.2 0z"
      transform="translate(-22.931 -10.737)"
      style={{
        fill: "#0069b4",
      }}
    />
    <path
      data-name="Trac\xE9 7911"
      d="M86.753 21.816c0-1.778-.925-3.379-2.762-3.379s-2.762 1.6-2.762 3.379.9 3.379 2.762 3.379 2.762-1.587 2.762-3.379m-7.823 0c0-3.056 2.057-5.406 5.053-5.406a5.138 5.138 0 0 1 5.053 5.406c0 3.041-2.042 5.406-5.053 5.406a5.087 5.087 0 0 1-5.053-5.406"
      transform="translate(-28.285 -10.673)"
      style={{
        fill: "#0069b4",
      }}
    />
    <path
      data-name="Trac\xE9 7912"
      d="M108.043 16.41c1.072 0 3.158.353 3.158 1.645a.933.933 0 0 1-.911.984c-.6 0-1.014-.514-2.248-.514-1.822 0-2.762 1.542-2.762 3.335 0 1.748.955 3.247 2.762 3.247 1.234 0 1.733-.617 2.336-.617a1.034 1.034 0 0 1 .97 1c0 1.41-2.218 1.733-3.305 1.733a5.087 5.087 0 0 1-5.053-5.406 5.061 5.061 0 0 1 5.053-5.406"
      transform="translate(-34.673 -10.673)"
      style={{
        fill: "#0069b4",
      }}
    />
    <path
      data-name="Trac\xE9 7913"
      d="M125.37 17.676a1.1 1.1 0 1 1 2.2 0v3.114h4.613v-3.114a1.1 1.1 0 1 1 2.2 0v8.344a1.1 1.1 0 1 1-2.2 0v-3.2h-4.613v3.2a1.1 1.1 0 1 1-2.2 0z"
      transform="translate(-40.615 -10.705)"
      style={{
        fill: "#0069b4",
      }}
    />
    <path
      data-name="Trac\xE9 7914"
      d="M149.06 17.928c0-.735.367-1.278 1.146-1.278h3.643a.978.978 0 0 1 1.043 1.014.994.994 0 0 1-1.043 1.014h-2.585v2.145h2.409a1 1 0 0 1 1.072 1.014 1.011 1.011 0 0 1-1.072 1.014h-2.409v2.233h2.718a.978.978 0 0 1 1.043 1.014.994.994 0 0 1-1.043 1.014h-3.79a1.072 1.072 0 0 1-1.131-1.1z"
      transform="translate(-46.904 -10.737)"
      style={{
        fill: "#0069b4",
      }}
    />
    <path
      data-name="Trac\xE9 7915"
      d="M212.243 16.41c1.072 0 3.158.353 3.158 1.645a.933.933 0 0 1-.911.984c-.6 0-1.014-.514-2.248-.514-1.822 0-2.762 1.542-2.762 3.335 0 1.748.955 3.247 2.762 3.247 1.234 0 1.733-.617 2.336-.617a1.034 1.034 0 0 1 .97 1c0 1.41-2.218 1.733-3.305 1.733a5.087 5.087 0 0 1-5.053-5.406 5.061 5.061 0 0 1 5.053-5.406"
      transform="translate(-62.337 -10.673)"
      style={{
        fill: "#0069b4",
      }}
    />
    <path
      data-name="Trac\xE9 7916"
      d="M229.57 17.676a1.1 1.1 0 1 1 2.2 0v3.114h4.613v-3.114a1.1 1.1 0 1 1 2.2 0v8.344a1.1 1.1 0 1 1-2.2 0v-3.2h-4.613v3.2a1.1 1.1 0 1 1-2.2 0z"
      transform="translate(-68.279 -10.705)"
      style={{
        fill: "#0069b4",
      }}
    />
    <path
      data-name="Trac\xE9 7917"
      d="M253.26 17.928c0-.735.367-1.278 1.146-1.278h3.643a.978.978 0 0 1 1.043 1.014.994.994 0 0 1-1.043 1.014h-2.585v2.145h2.409a1 1 0 0 1 1.072 1.014 1.011 1.011 0 0 1-1.072 1.014h-2.409v2.233h2.718a.978.978 0 0 1 1.043 1.014.994.994 0 0 1-1.043 1.014h-3.79a1.072 1.072 0 0 1-1.131-1.1z"
      transform="translate(-74.568 -10.737)"
      style={{
        fill: "#0069b4",
      }}
    />
    <path
      data-name="Trac\xE9 7918"
      d="M274.333 21.277h1.513a1.25 1.25 0 0 0 1.366-1.307 1.232 1.232 0 0 0-1.366-1.293h-1.513zm-2.2-3.482a1.04 1.04 0 0 1 1.131-1.146h2.586c2.262 0 3.658 1.014 3.658 3.32a2.852 2.852 0 0 1-2.718 2.776l2.5 2.674a.952.952 0 0 1 .294.646 1.114 1.114 0 0 1-1.954.749l-3.261-3.952h-.029v3.188a1.1 1.1 0 1 1-2.2 0V17.8z"
      transform="translate(-79.578 -10.737)"
      style={{
        fill: "#0069b4",
      }}
    />
  </svg>
);
