import { Section } from "@/components/common/section/Section";
import { ServiceCard, ServiceCardProps } from "./serviceCard/ServiceCard";

import styles from "./Services.module.scss";

export type ServicesBlock = {
  title: string;
  serviceCards: ServiceCardProps[];
};

export const Services = ({ title, serviceCards }: ServicesBlock) => (
  <Section>
    <div className={styles.servicesBlock}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.services}>
        {serviceCards.map((serviceCard, index) => (
          <div key={serviceCard.title} className={styles[`card-${index + 1}`]}>
            <ServiceCard {...serviceCard} />
          </div>
        ))}
      </div>
    </div>
  </Section>
);

export default Services;
