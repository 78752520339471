import { Modal } from "@mantine/core";
import { useIntl } from "react-intl";
import router from "next/router";
import { ModalClose } from "@/public/icons/ModalClose";
import { routes } from "@/routes/routes";
import styles from "./LocaBestModal.module.scss";

type LocaBestModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpened: boolean) => void;
};

export const LocaBestModal = ({ isOpen, setIsOpen }: LocaBestModalProps) => {
  const intl = useIntl();
  function closeModale() {
    setIsOpen(false);
    void router.push(routes.home);
  }

  return (
    <Modal
      opened={isOpen}
      centered
      withCloseButton={false}
      onClose={() => setIsOpen(false)}
      classNames={{
        modal: styles.modalRoot,
        body: styles.modalBody,
        overlay: styles.modalOverlay,
      }}
    >
      <button className={styles.modalClose} onClick={closeModale}>
        <ModalClose color="#062E5E" />
      </button>
      <p className={styles.modalText}>
        {intl.formatMessage({ id: "locaBestMessageTitle" })}
      </p>
      <p className={styles.modalText}>
        {intl.formatMessage({ id: "locaBestMessage" })}
      </p>
    </Modal>
  );
};
